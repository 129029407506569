import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
export default {
  name: "profile",
  components:{
  },
  data(){
    return{

    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
      favoriteList: 'basket/favorites',
      isRetailer: 'profile/isRetailer'
    }),
  },

  methods:{
    ...mapMutations({
    }),
    ...mapActions({
      signOut: `auth/LOGOUT`
    }),
    logout(){
      this.signOut().then((result) => {
        this.$router.push({name: 'home'})
      }).catch(error => {
        if (error.status === 422) {
          this.$toasted.error(error.data.message);
          const errors = error.data.errors;
          for(const i in errors){
            errors[i].forEach(e => {
              this.$toasted.error(e);
            })
          }
        }
      })
    }
  }
}